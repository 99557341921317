$calls-grid-breakpoints: (
    // Extra small screen / phone
    xs: 0,
    // Small screen / phone
    sm: 576px,
    // Medium screen / tablet
    md: 768px,
    // Large screen / desktop
    lg: 992px,
    // Extra large screen / wide desktop
    xl: 1200px
);

.custom-orders-list-row {
    @include make-grid-columns(30, 1rem, $calls-grid-breakpoints);
}
.custom-orders-list-row-form {
    @include make-grid-columns(32, 0.9rem, $calls-grid-breakpoints);
}
.custom-orders-list-row-add-item {
    @include make-grid-columns(28, 1rem, $calls-grid-breakpoints);
}
.custom-orders-table-head {
    background-color: #f8f9fa;
}

.extended-input-input-orders {
    position: absolute;
    top: 0px;
    right: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.custom-orders-table-row {
    border-bottom: 2px solid #eff2f7;

    &:last-child {
        border-bottom: none !important;
    }
}

@include media-breakpoint-down(lg) {
    .custom-orders-page-order-height {
        height: 100% !important;
    }
}

.custom-orders-page-order-height {
    height: calc(100vh - 13rem);
}

.background-order-filter {
    background-color: #ffffff;
}

.search-order-filter-button {
    color: white;
}

.custom-global-search-background {
    background-color: #fff !important
}
