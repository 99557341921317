.team-carousel {
    .VueCarousel-inner {
        flex-basis: auto !important;
        .VueCarousel-slide {
            margin-right: 24px;
            width: 255px;
            .team-box {
                img {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}

// .VueCarousel-dot.VueCarousel-dot--active {
//     width: 30px !important;
//     height: 30px !important;
//     line-height: 28px !important;
//     font-size: 20px !important;
//     border-radius: 50% !important;
//     background-color: rgba(85, 110, 230, 0.25) !important;
//     color: #556ee6 !important;
//     margin: 4px 8px !important;
//     position: relative;
//     &::before{
//         content: "\F0141";
//     }
//     // .VueCarousel-dot--active {
//     //
//     // }
// }
