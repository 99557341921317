.popover {
  box-shadow: 0px -1px 5px 0px rgba(68, 68, 68, 0.20);
}

.text-2x {
  font-size: 200%;
}

.text-lg {
  font-size: 150%;
}

.popover-body {
  font-size: 0.8rem
}

.cursor {
  &-pointer {
    cursor: pointer;
  }

  &-not-allowed {
    cursor: not-allowed;
  }
}

.hover-shadow:hover {
  @extend .shadow
}

.hover-shadow-lg:hover {
  @extend .shadow-lg
}

.text-strong {
  font-weight: bold;
}

@for $i from 13 through 17 {
  .font-size-#{$i*2} {
    font-size: #{$i*2px} !important;
  }
}

@for $i from 1 through 20 {
  .opacity-#{$i*5} {
    opacity: #{$i*5%};
  }
}

.text__highlight {
  @extend .text-primary;
  background: none !important;
  border-radius: 0;
  padding: 0;
}

.index-card-logo {
  min-width: 100px;
  min-height: 100px;
  height: 100px;
  width: 100%;
  max-width: 100px;
  max-height: 100px;
}

.h-fill {
  min-height: 100%;
  height:100%;
}

html.fill {
  @extend .h-fill;

  body {
    @extend .h-fill;
  }

  #app {
    @extend .h-fill;
  }
}

.pre-wrap {
  white-space: pre-wrap !important;
}

.border-dark-gray {
  border-color: #ced4da;
}

.w-20px {
  width: 20px;
}

.w-50px {
  width: 50px;
}

.w-80px {
  width: 50px;
}

.w-100px {
  width: 50px;
}

.w-150px {
  width: 50px;
}

.developers-blank-span {
  -webkit-animation: developersBlankSpan 2s linear infinite;
  animation: developersBlankSpan 2s linear infinite;
}

@-webkit-keyframes developersBlankSpan {
  50% {
    opacity: 0;
  }
}

@keyframes developersBlankSpan {
  50% {
    opacity: 0;
  }
}

.IZ-select__item--selected {
  background: #f2f2f2;
}

.animation-d3 {
  animation-duration: 0.3s
}

.image-24 {
  max-width: 24px;
  max-height: 24px;
}

.image-50 {
  max-width: 50px;
  max-height: 50px;
}


.text-underline {
  text-decoration: underline !important;
}

.animation-d3 {
  animation-duration: 0.3s
}

.page-order{
  &-height{
    height: calc(100vh - 13rem) !important;
  }
}

.form-control::placeholder{
  color: darkgrey;
}
